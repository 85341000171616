import React from "react";
import styled from "@emotion/styled";
// import quote from "../assets/images/quote.svg";

// Interfaces
interface CaseProps {
    name: string;
    role: string;
    quoteText: string;
    src: string;
    backgroundColor: string;
}

// Styling
const Root = styled.div<{ backgroundColor: string }>`
    display: flex;
    flex-flow: column;
    width: 100%;
    // height: 350px;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    background-color: ${(props) => props.backgroundColor};
    margin: 2rem -2rem;
    padding: 0;
    ${(props) => props.theme.mq[2]} {
        width: calc(100% + 80px);
        padding: 0 15px;
        margin: 5rem 0;
    }

    .bioBox {
        height: 360px;
        padding: 0px 25px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: 768px) {
            height: 185px;
        }

        .name {
            font-size: 30px;
            font-weight: bold;
            margin: 0;
            padding: 0;
            position: absolute;
            top: 15%;
            left: 20%;

            @media screen and (max-width: 768px) {
                font-size: 14px;
                top: 40%;
                position: absolute;
                left: 20px;
            }
        }

        .role {
            font-size: 30px;
            margin: 0;
            position: absolute;
            top: 25%;
            left: 20%;

            @media screen and (max-width: 768px) {
                font-size: 14px;
                position: absolute;
                top: 50%;
                left: 20px;
            }
        }

        img {
            position: absolute;
            width: 504px;
            height: 416px;
            bottom: 0px;
            right: 0px;
            z-index: 1;
            // margin-left: 10px;
            // margin-top: 10px;

            @media screen and (max-width: 768px) {
                width: 252px;
                height: 208px;
            }
        }
    }

    .quoteTextMobile {
        // text-align: left;
        // z-index: 99;
        // font-size: 20px;
        // color: #000000;
        // font-style: italic !important;
        // margin: 20px 20px 20px 150px;
        // max-width: 30%;
        visibility: hidden;

        @media screen and (max-width: 990px) {
            font-size: 20px;
            margin: 20px;
            font-style: italic !important;
            max-width: 100%;
            visibility: visible;
        }
    }

    .quoteTextDesktop {
        visibility: visible;
        position: absolute;
        font-style: italic !important;
        top: 45%;
        left: 20%;
        max-width: 35%;

        @media screen and (max-width: 990px) {
            visibility: hidden;
        }
    }
`;

// Markup
const Article = ({
    name,
    role,
    quoteText,
    src,
    backgroundColor,
}: CaseProps) => (
    <Root backgroundColor={backgroundColor}>
        <div style={{ position: "relative" }}>
            <div style={{ backgroundColor: "#F2F2F2" }}>
                <div className="bioBox">
                    <img src={src} alt="quote" />
                    <p className="name">{name}</p>
                    <p className="role">{role}</p>
                    <p className="quoteTextDesktop">{quoteText}</p>
                </div>
            </div>
            <p className="quoteTextMobile">{quoteText}</p>
        </div>
    </Root>
);

export const QuoteAndImage = Article;
