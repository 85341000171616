// Emotion types
/// <reference types="@emotion/react/types/css-prop" />
import React, { useEffect, useState } from "react";
import { Finnish, Swedish } from "../views";

// Markup
const Index = () => {

    const [swedish, setSwedish] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (typeof window !== `undefined`) {
            const params = new URLSearchParams(window.location.search);
            if (params.get("lang") !== "finnish") {
                if (
                    window.location.host.includes(".se") ||
                    window.location.host.includes("localhost")
                ) {
                    setSwedish(true);
                } else {
                    setSwedish(false);
                }
            } else {
                setSwedish(false);
            }
            setLoaded(true);
        }
    }, [loaded]);

    if(loaded) {
        if (swedish) {
            return <Swedish />;
        } else {
            return <Finnish />;
        }
    } else {
        return <></>
    }

};

export default Index;
