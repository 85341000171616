import React, { ReactNode } from "react";
import styled from "@emotion/styled";

// Interface
interface HeroProps {
    backgroundImage: string;
    children?: ReactNode;
}

// Styling
const Section = styled.section<{ backgroundImage: string }>`
    background-image: ${(props) => `url(${props.backgroundImage})`};
`;

// Markup
export const Hero = ({ backgroundImage, children }: HeroProps) => {
    return (
        <Section /* className="vf-hero" */ style={{ position: "relative", backgroundSize: "cover", backgroundPosition: "center center", margin: "0 auto", width: "100%", maxWidth: "1700px", height: "60vh" }} backgroundImage={backgroundImage}>
            <div className="vf-hero__content">{children}</div>
        </Section>
    );
};
