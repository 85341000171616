import React from "react";
import styled from "@emotion/styled";
import ecoGainLogo from "../assets/images/fi-flight4-22/ecogain-logo.png";
import ecoVadisLogo from "../assets/images/fi-flight4-22/ecovadis-logo.png";
import sbiLogo from "../assets/images/fi-flight4-22/sbi-logo.png";
import sbtiLogo from "../assets/images/fi-flight4-22/sbti-logo.png";

const AchievementsWrapper = styled.section`
    width: 100%;

    .achievements-headline {
        text-align: center;
    }

    .achievements-container {
        display: flex;
        flex-direction: row;
        gap: 30px;
        max-width: 65%;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    .achievement {
        display: flex;
        flex-direction: column;
        min-width: 150px;
        p {
            font-size: 14px;
            line-height: 1.5rem;
            max-width: auto;
        }

        .img-wrapper {
            width: 150px;
            height: 48px;
            margin-bottom: 30px;

            .image {
                // max-width: 100%;
                // max-height: 100%;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
`;

// Markup
export const Achievements = () => {
    return (
        <>
            <AchievementsWrapper>
                <h4 className="achievements-headline">Saavutuksiamme</h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="achievements-container">
                        <div className="achievement">
                            <div className="img-wrapper">
                                <div className="image image-one">
                                    <img src={sbtiLogo} />
                                </div>
                            </div>
                            <p>
                                Päästövähennyssuunnitelmamme on vahvistettu
                                Pariisin ilmastosopimuksen mukaiseksi.
                            </p>
                        </div>
                        <div className="achievement">
                            <div className="img-wrapper">
                                <div className="image image-two">
                                    <img src={sbiLogo} />
                                </div>
                            </div>
                            <p>
                                Kuluttajat valitsivat Vattenfallin{" "}
                                <a href="https://www.vattenfall.fi/fokuksessa/fossiilivapaa/suomen-vastuullisin-sahkoyhtio-2023/">
                                     Suomen vastuullisimmaksi sähköyhtiöksi vuonna 2023
                                </a>
                                , jo viidentenä vuonna peräkkäin. 
                            </p>
                        </div>
                        <div className="achievement">
                            <div className="img-wrapper">
                                <div className="image image-three">
                                    <img src={ecoVadisLogo} />
                                </div>
                            </div>
                            <p>
                                Ecovadisin korkein Platinum-tason luokitus
                                ympäristönäkökohtien, etiikan ja toimitusketjun
                                vastuullisuuden osalta.
                            </p>
                        </div>
                        <div className="achievement">
                            <div className="img-wrapper">
                                <div className="image image-four">
                                    {" "}
                                    <img src={ecoGainLogo} />
                                </div>
                            </div>
                            <p>
                                Ecogain sertifikaatti ja tunnustus
                                kunnianhimoisesta tavoitteiden asetannasta
                                luonnon monimuotoisuuden turvaamiseksi.
                            </p>
                        </div>
                    </div>
                </div>
            </AchievementsWrapper>
        </>
    );
};
