import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import metadataImage from "../assets/images/metadata-image.jpg";

// Markup
export const Metadata = () => {
    const { site } = useStaticQuery(query);
    const { defaultTitle, titleTemplate, defaultDescription, siteUrl } =
        site.siteMetadata;

    const language = process.env.LANGUAGE === "swe" ? "sv-SE" : "fi-FI";

    return (
        <Helmet title={defaultTitle} titleTemplate={titleTemplate}>
            <html lang={language} />
            <meta name="description" content={defaultDescription} />
            <meta name="image" content={metadataImage} />
            <meta property="og:url" content={siteUrl} />
        </Helmet>
    );
};

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl
            }
        }
    }
`;
