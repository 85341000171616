import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
// import { css } from "@emotion/react";

// Interfaces
interface CaseProps {
    headline: string;
    quoteText: string;
    backgroundColor: string;
    textWidth: string;
    video: string;
    link?: string;
    startVideo: boolean;
}

// Styling
const Root = styled.div<{ backgroundColor: string; textWidth: string }>`
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    // max-height: 350px;
    background-color: ${(props) => props.backgroundColor};
    margin: 2rem -2rem;
    // flex-flow: column;
    padding-bottom: 40px;
    ${(props) => props.theme.mq[2]} {
        width: calc(100% + 80px);
        padding: 0 15px;
        margin: 5rem 0;
        flex-flow: row;
        height: 350px;
    }

    @media screen and (max-width: 992px) {
        padding-top: 40px;
        max-height: 850px;
    }
    @media screen and (max-width: 480px) {
        padding-bottom: 10px;
        padding-top: 0;
    }

    .content {
        width: 44%;
        background-color: #edf1f6;
        display: flex;
        flex-flow: column;
        padding: 0px 20px;

        @media screen and (max-width: 992px) {
            width: 95%;
            margin-top: 40px;
        }

        @media screen and (max-width: 480px) {
            width: 100%;
        }
        a {
            margin-top: 20px;
        }
        p {
            text-align: left;
            z-index: 2;
            color: #000000;
        }
        p:first-of-type {
            font-weight: 700;
            text-align: left;
            margin: 25px 0px 15px 0px;
            font-size: 18px;
            max-width: ${(props) => props.textWidth};
        }
        p:nth-of-type(2) {
            line-height: 28px;
        }
        p:last-of-type {
            margin-bottom: 0;
        }
        img {
            position: absolute;
            top: -15%;
            left: 18%;
            width: 250px;
            z-index: 1;

            @media screen and (max-width: 992px) {
                top: -5%;
                left: 40%;
            }
            @media screen and (max-width: 480px) {
                width: 220px;
                left: 5%;
            }
        }
    }
`;

const Container = styled.div`
    width: 100%;
    max-width: 1380px;
    display: flex;
    flex-flow: row;
    padding: 80px;
    align-items: center;
    max-height: 400px;
    padding: 80px;
    justify-content: space-between;
    background-color: #edf1f6;

    @media screen and (max-width: 992px) {
        padding: 20px 0px;
    }
    @media screen and (max-width: 480px) {
        padding-top: 20px;
        background-color: #fff;
        flex-flow: column;
        max-height: 700px;
    }
`;

const Section = styled.section`
    width: 100%;
    border-left: none;
    border-right: none;

    @media screen and (max-width: 992px) {
        margin-top: 0px;
        max-width: 90%;
    }
    @media screen and (max-width: 1124px) {
        margin-top: 0px;
    }
`;

const Video = styled.video`
    width: 100%;
    border: solid 1px #edf1f6;
    z-index: 1;
    background-color: #fff;

    @media screen and (max-width: 480px) {
        border: none;
    }
`;

// Markup
const Article = ({
    video,
    headline,
    quoteText,
    backgroundColor,
    textWidth,
    startVideo,
}: CaseProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const playVideo = () => {
        videoRef.current?.play();
    };

    useEffect(() => {
        if (startVideo) {
            playVideo();
        }
    }, [startVideo]);

    return (
        <Root backgroundColor={backgroundColor} textWidth={textWidth}>
            <Container>
                <Section>
                    <Video muted playsInline loop ref={videoRef}>
                        <source src={video} type="video/mp4" />
                    </Video>
                </Section>
                <div className="content">
                    <p>{headline}</p>
                    {/* <p style={{ marginBottom: 0 }}>{quoteName}</p> */}
                    <p>{quoteText}</p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.se/energiradgivning/?icmp=elkampanjflight5_energianvandning"
                        style={{ paddingBottom: "25px" }}
                    >
                        Läs mer om smart energianvändning
                    </a>
                </div>
            </Container>
        </Root>
    );
};

export const CaseVideo = Article;
