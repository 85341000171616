import React, { useState } from "react";
import styled from "@emotion/styled";
import map from "../assets/map/map.png";
import mapMobile from "../assets/map/map-mobile.png";
import { MapMarker } from "./map-marker";

type location = {
    x: number;
    y: number;
    xMobile: number;
    yMobile: number;
    text: string;
    image: string;
};

interface MapProps {
    articleRefs: React.RefObject<HTMLDivElement>[];
    data: location[];
}

const Wrapper = styled.div`
    overflow: hidden;
    width: 100%;
    position: relative;
    .map-background {
        position: relative;
        display: block;
        height: 720px;
        width: 2163px;
        //background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 50%;
        transform: translateX(-50%);
        //image-rendering: pixelated;
        @media screen and (max-width: 700px) {
            margin-left: 60%;
        }
    }
`;
    
export const MapImage = ({ articleRefs, data }: MapProps) => {
    const [active, setActive] = useState<number | null>(null);
    
    return (
        <Wrapper>
            <div
                className="map-background"
                style={{ backgroundImage: `url("${window.innerWidth > 768 ? map : mapMobile}")` }}
            >
                {data.map(({ x, xMobile, y, yMobile, image, text }, idx) => (
                    <MapMarker
                        key={`marker-${idx}`}
                        articleRef={articleRefs[idx]}
                        idx={idx}
                        text={text}
                        image={image}
                        active={active}
                        setActive={setActive}
                        x={window.innerWidth > 700 ? x : xMobile}
                        y={window.innerWidth > 700 ? y : yMobile}
                    />
                ))}
            </div>
        </Wrapper>
    );
};
