import React from "react";
import { css, keyframes } from "@emotion/react";

import styled from "@emotion/styled";

// Interface
interface MapMarkerProps {
    idx: number;
    text: string;
    image: string;
    active: number | null;
    setActive: React.Dispatch<React.SetStateAction<number | null>>;
    articleRef: React.RefObject<HTMLDivElement>;
    x: number;
    y: number;
}

const pulse = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(217, 193, 50, 0.95);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(217, 193, 50, 0);
    }
    80% {
        box-shadow: 0 0 0 25px rgba(217, 193, 50, 0);
    }
    90% {
        box-shadow: 0 0 0 0px rgba(217, 193, 50, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(217, 193, 50, 0);
    }
`;

// Styling
const Container = styled.div<{ active: boolean }>`
    position: absolute;
    z-index: ${(props) => (props.active ? 10 : 1)} !important;
`;

const Circle = styled.div`
    position: absolute;
    background-color: #ffda00;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
`;

const Info = styled.div`
    width: 120px;
    height: 120px;
    padding: 0.5rem;
    transform: translate(-50%, -50%);
    cursor: pointer;

    ${(props) => props.theme.mq[2]} {
        width: 180px;
        height: 180px;
    }
`;

const Background = styled.img`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

const Text = styled.p`
    color: #fff;
    position: absolute;
    text-shadow: 0 0 5px #000;
    line-height: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: 600;
`;

const PosWrapper = styled.div<{y: number, x: number}>`
    position: absolute;
    left: ${props => props.x}px;
    top: ${props => props.y}px;
`;

// Markup
export const MapMarker = ({
    idx,
    //position,
    text,
    image,
    active,
    setActive,
    articleRef,
    x,
    y
}: MapMarkerProps) => {
    const scrollToArticle = () => {
        const articlePosition = articleRef.current?.offsetTop;
        const desktopUser = window.innerWidth > 992;
        /*if (articlePosition && desktopUser) {
            const offsetPosition = articlePosition - 150;
            window.scrollTo({ top: offsetPosition, behavior: "smooth" });
        }*/
        if (articlePosition) {
            const offsetPosition = articlePosition - 150;
            window.scrollTo({ top: offsetPosition, behavior: "smooth" });
        }
    };

    return (
        <PosWrapper x={x} y={y}>
            <Container active={idx === active}>
                <img src={image} alt="prelaod" style={{ display: "none" }} />

                {idx !== active && (
                    <Circle
                        onMouseOver={() => setActive(idx)}
                        css={css`
                            animation: ${pulse} 2s infinite;
                        `}
                    />
                )}
                {idx === active && (
                    <Info
                        onMouseOut={() => setActive(null)}
                        onClick={() => scrollToArticle()}
                    >
                        <Background src={image} />
                        <Text>{text}</Text>
                    </Info>
                )}
            </Container>
        </PosWrapper>
    );
};
