import React from "react";
import styled from "@emotion/styled";
import quote from "../assets/images/quote.svg";

// Interfaces
interface CaseProps {
    text: string;
    quoteName: string;
    quoteText: string;
    backgroundColor: string;
    textWidth: string;
}

// Styling
const Root = styled.div<{ backgroundColor: string; textWidth: string }>`
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 350px;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    background-color: ${(props) => props.backgroundColor};
    margin: 2rem -2rem;
    padding: 0;
    ${(props) => props.theme.mq[2]} {
        width: calc(100% + 80px);
        padding: 0 15px;
        margin: 5rem 0;
    }

    div {
        position: relative;
        @media screen and (max-width: 768px) {
            padding: 20px;
        }
        p {
            text-align: right;
            z-index: 2;
            position: relative;
            color: #4e4b48;
            max-width: ${(props) => props.textWidth};
        }
        p:first-of-type {
            font-weight: 500;
            line-height: 31px;
            font-size: 26px;
        }
        p:nth-of-type(2) {
            font-style: italic;
            line-height: normal;
        }
        p:last-of-type {
            font-style: italic;
            margin-bottom: 0;
        }
        img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 250px;
            z-index: 1;
            margin-left: -20px;
            margin-top: -20px;
            @media screen and (max-width: 768px) {
                width: 220px;
                left: 30%;
                top: 5%;
                transform: translate(-30%, -5%);
            }
        }
    }
`;

// Markup
const Article = ({
    text,
    quoteName,
    quoteText,
    backgroundColor,
    textWidth,
}: CaseProps) => (
    <Root backgroundColor={backgroundColor} textWidth={textWidth}>
        <div>
            <p>{text}</p>
            <p style={{ marginBottom: 0 }}>{quoteName}</p>
            <img src={quote} alt="quote" />
            <p>{quoteText}</p>
        </div>
    </Root>
);

export const Quote = Article;
