import React, { ForwardedRef, forwardRef, ReactNode } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

// Interface
interface VideoHeroProps {
    mp4: string;
    webM: string;
    primary?: boolean;
    children?: ReactNode;
    subtitles?: string;
    subLang?: string;
    subLabel?: string;
    minHeight?: boolean;
}

// Styling
const Section = styled.section<{ primary: boolean; minHeight: boolean }>`
    max-width: none;
    height: calc(100vh - 100px);
    min-height: ${(props) => (props.minHeight ? "700px" : "0")};
    max-height: 697.8px;
    padding: 2rem 2rem 0rem 2rem;
    position: relative;
    overflow: hidden;
    border-left: none;
    border-right: none;
    margin: ${(props) => (props.primary ? 0 : "2rem")} 0 2rem;
    ${(props) =>
        props.theme.mq[2] &&
        props.primary &&
        css`
            padding-bottom: 56.25%;
        `};

    // .video-hero-fi {
    //     height: 570px;
    //     position: absolute;
    //     display: flex;
    //     align-items: center;
    //     top: 0;
    //     @media screen and (max-width: 768px) {
    //         top: auto;
    //         padding: 20px;
    //     }
    //     .video-hero-fi-text {
    //         color: #fff;
    //         // font-size: 24px;
    //         // font-weight: 600;
    //         max-width: 730px;
    //         @media screen and (max-width: 768px) {
    //             // font-size: 16px;
    //         }
    //         a {
    //             color: #fff;
    //             text-decoration: underline;
    //             font-size: 24px;
    //             font-weight: 600;
    //             @media screen and (max-width: 768px) {
    //                 font-size: 16px;
    //             }
    //         }
    //     }
    // }

    .video-hero-h2 {
        font-size: 36px;

        @media screen and (max-width: 480px) {
            font-size: 28px;
        }
    }

    .video-hero-container {
        max-width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
            font-size: 20px;
            line-height: 1.7rem;

            @media screen and (max-width: 480px) {
                font-size: 16px !important;
            }
        }

        @media screen and (max-width: 768px) {
            max-width: 80%;
        }

        @media screen and (max-width: 480px) {
            max-width: 90% !important;
        }
    }
`;

const Video = styled.video`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    pointer-events: ${(props) => (props.controls ? "all" : "inherit")};
    ${(props) => props.theme.mq[2]} {
        object-fit: inherit;
    }

    ::cue {
        font-family: "Vattenfall Hall NORDx", -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";
        background: none;
        background-color: rgba(0, 0, 0, 0) !important;
        text-shadow: 0 0 10px #000;
    }
    ::-webkit-media-text-track-display-backdrop {
        background-color: rgba(0, 0, 0, 0) !important;
    }
`;

const Content = styled.div<{ primary: boolean }>`
    pointer-events: ${(props) => (props.primary ? "none" : "inherit")};
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    padding: 2rem;

    & > * {
        text-align: center;
    }
`;

// Markup
export const VideoHeroWithoutRef = (
    {
        children,
        mp4,
        webM,
        primary = false,
        subtitles,
        subLang,
        subLabel,
        minHeight = false,
    }: VideoHeroProps,
    ref: ForwardedRef<HTMLDivElement>
) => (
    <Section
        className="vf-hero"
        primary={primary}
        ref={ref}
        minHeight={minHeight}
    >
        <Video autoPlay muted playsInline loop controls={primary}>
            <source src={webM} type="video/webm" />
            <source src={mp4} type="video/mp4" />
            subtitles && (
            <track
                label={subLabel}
                kind="subtitles"
                srcLang={subLang}
                src={subtitles}
            ></track>
            )
        </Video>
        <Content className="vf-hero__content" primary={primary}>
            {children}
        </Content>
    </Section>
);

export const VideoHero = forwardRef(VideoHeroWithoutRef);
