import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
// import Wind from "../assets/images/wind.svg";
import { motion } from "framer-motion";
import hydrogenQuiz from "../assets/videos2/hydrogenQuiz.mp4";
import rightAnswerIcon from "../assets/images/fi-flight5-23/right-answer.png";
import wrongAnswerIcon from "../assets/images/fi-flight5-23/wrong-answer.png";

const Container = styled.div`
    width: 410px;

    @media screen and (max-width: 990px) {
        width: 100%;
    }
`;

const Header = styled.div`
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    margin-bottom: 20px;

    @media screen and (max-width: 990px) {
        margin-bottom: 0;
    }

    h2 {
        line-height: normal;
        margin-right: 25px;

        @media screen and (max-width: 480px) {
            margin-right: 0px;
            margin-bottom: 28px;
        }
    }

    .wind {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;

        img {
            width: 100px;
            animation: spin 6s linear infinite;
            animation-duration: 6s;
            transform-origin: 50% 63%;
            @media screen and (min-width: 990px) {
                width: 130px;
            }
            &.fast {
                animation-duration: 2.4s;
            }
            &.faster {
                animation-duration: 1.6s;
            }
            &.fastest {
                animation-duration: 1s;
            }
        }

        .pole {
            width: 16px;
            background: #000;
            height: 77px;
            margin-bottom: -20px;
            position: relative;
            bottom: 15px;
            @media screen and (min-width: 990px) {
                height: 84px;
                bottom: 19px;
            }
        }
    }

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Question = styled.div`
    @media screen and (max-width: 990px) {
        transform: translateY(-10px);
    }
    .question {
        h4 {
            margin-bottom: 5px;
            @media screen and (min-width: 990px) {
                font-size: 24px;
                margin-bottom: 10px;
            }
        }
        p {
            line-height: 28px;
            &:last-of-type {
                @media screen and (max-width: 990px) {
                    line-height: 20px;
                }
            }
        }
    }
    .options {
        margin-top: -10px;
        .buttons {
            margin-bottom: 25px;
            @media screen and (max-width: 990px) {
                display: flex;
                margin-bottom: 0px;
            }
            button {
                // background-color: #1964a3;
                color: white;

                // :active {
                //     background-color: #2071b5;
                // }
                @media screen and (max-width: 990px) {
                    width: 130px;
                    min-width: 0;
                }
                &:first-of-type {
                    margin-right: 15px;
                }
            }
        }
    }
`;

const Answer = styled(motion.div)`
    padding: 20px;
    border-radius: 4px;
    background: #f1f1f1;
    opacity: 0;
    transform: translateY(0);
    display: none;
    @media screen and (max-width: 990px) {
        margin-top: 20px;
    }
    h4 {
        margin-bottom: 8px;
    }
    p {
        line-height: 28px;
        margin-bottom: 0;
        @media screen and (max-width: 990px) {
            line-height: 20px;
        }
    }
    .next {
        margin-top: 15px;
        color: #1964a3;
        cursor: pointer;
        transform: translateX("-50px");
        opacity: 0;
    }
`;

const CheckAnswer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-bottom: 15px;

    h4 {
        margin: 0 0 0 15px;
    }
`;

const variants = {
    open: { y: 0, opacity: 1, display: "block" },
    closed: { y: -50, opacity: 0, display: "none" },
};

type QuizButtonProps = {
    selected: boolean;
};

const QuizButton = styled.button`
    background-color: ${({ selected }: QuizButtonProps) =>
        selected ? "#2071b5 !important" : "#1964a3 !important"};
`;

export const Quiz = () => {
    // const [speed, setSpeed] = useState<0 | 1 | 2 | 3>(0);

    const [currentQuestion, setCurrentQuestion] = useState<1 | 2 | 3>(1);
    const [question1, setQuestion1] = useState<boolean>(false);
    const [question2, setQuestion2] = useState<boolean>(false);
    const [question3, setQuestion3] = useState<boolean>(false);
    const [rightAnswer, setRightAnswer] = useState<boolean>(false);
    const [selectedAnswer, setSelectedAnswer] = useState<string>("");

    /*const getCurrentRotation = () => {
        const el = document.getElementById("wind");
        const st = window.getComputedStyle(el!, null);
        const tm = st.getPropertyValue("transform");
        let rot = 0;
        
        if (tm) {
            const values = tm.split("(")[1].split(")")[0].split(",");
            
            const angle = Math.round(
                Math.atan2(Number(values[1]), Number(values[0])) * (180 / Math.PI)
            );

            rot = angle < 0 ? angle + 360 : angle;
            console.log(rot);          
            
            return angle < 0 ? angle + 360 : angle;
        }
        
        return 0;
    }*/

    // whenever the user goes to next question, reset state to reset styling of buttons
    useEffect(() => {
        setSelectedAnswer("");
    }, [currentQuestion]);

    return (
        <Container>
            {/* <QuizButton
                className="vf-btn vf-btn--sm vf-btn--primary"
                selected={selectedAnswer === "Ja"}
                onClick={() => {
                    setSelectedAnswer("Ja");
                }}
            >
                Ja
            </QuizButton>
            <QuizButton
                className="vf-btn vf-btn--sm vf-btn--primary"
                selected={selectedAnswer === "Nej"}
                onClick={() => {
                    setSelectedAnswer("Nej");
                }}
            >
                Nej
            </QuizButton> */}
            <Header>
                <div>
                    <video
                        width="150"
                        height="150"
                        style={{ zIndex: 1 }}
                        autoPlay
                        muted
                        playsInline
                        loop
                    >
                        <source src={hydrogenQuiz} type="video/mp4" />
                    </video>
                    <h2>Mitä tiedät vedystä?</h2>
                </div>
            </Header>

            {currentQuestion === 1 && (
                <Question>
                    <div className="question">
                        {/* <p style={{ marginBottom: "-3px", fontSize: "14px" }}>
                            1/3
                        </p> */}
                        <h4>Kysymys 1:</h4>
                        <p>
                            Voidaanko teollisuuden päästöistä tehdä harmittomia?
                        </p>
                    </div>
                    <div className="options">
                        <div className="buttons">
                            <QuizButton
                                selected={selectedAnswer === "Kyllä"}
                                type="button"
                                className="vf-btn vf-btn--sm vf-btn--primary"
                                onClick={() => {
                                    setSelectedAnswer("Kyllä");
                                    setQuestion1(true);
                                    setRightAnswer(true);
                                    // setSpeed(1);
                                }}
                            >
                                Kyllä
                            </QuizButton>
                            <QuizButton
                                selected={selectedAnswer === "Ei"}
                                type="button"
                                className="vf-btn vf-btn--sm vf-btn--primary"
                                onClick={() => {
                                    setSelectedAnswer("Ei");
                                    setQuestion1(true);
                                    setRightAnswer(false);
                                    // setSpeed(0);
                                }}
                            >
                                Ei
                            </QuizButton>
                        </div>
                    </div>

                    <Answer
                        animate={question1 ? "open" : "closed"}
                        variants={variants}
                        transition={{
                            type: "ease",
                            default: { duration: 0.4 },
                        }}
                    >
                        <CheckAnswer>
                            {rightAnswer === true ? (
                                <>
                                    <img src={rightAnswerIcon} />{" "}
                                    <h4>Oikea vastaus</h4>
                                </>
                            ) : (
                                <>
                                    <img src={wrongAnswerIcon} />{" "}
                                    <h4>Väärä vastaus</h4>
                                </>
                            )}
                        </CheckAnswer>
                        <p>
                            Kyllä voidaan. Kun vetyä käytetään polttoaineena, on
                            päästönä vain vesihöyryä.
                        </p>
                        <motion.p
                            animate={{
                                x: question1 ? 0 : -40,
                                opacity: question1 ? 1 : 0,
                            }}
                            transition={{
                                delay: 0.4,
                                type: "spring",
                                stiffness: 100,
                            }}
                            className="next"
                            onClick={() => setCurrentQuestion(2)}
                        >
                            &#62; Seuraava kysymys
                        </motion.p>
                    </Answer>
                </Question>
            )}

            {currentQuestion === 2 && (
                <Question>
                    <div className="question">
                        {/* <p style={{ marginBottom: "-3px", fontSize: "14px" }}>
                            2/3
                        </p> */}
                        <h4>Kysymys 2:</h4>
                        <p>Onko mahdollista tuottaa tarpeeksi vetyä?</p>
                    </div>
                    <div className="options">
                        <div className="buttons">
                            <QuizButton
                                selected={selectedAnswer === "Kyllä"}
                                onClick={() => {
                                    setQuestion2(true);
                                    setRightAnswer(true);
                                    setSelectedAnswer("Kyllä");
                                    // setSpeed(1);
                                }}
                                type="button"
                                className="vf-btn vf-btn--sm vf-btn--primary"
                            >
                                Kyllä
                            </QuizButton>
                            <QuizButton
                                selected={selectedAnswer === "Ei"}
                                onClick={() => {
                                    setQuestion2(true);
                                    setRightAnswer(false);
                                    setSelectedAnswer("Ei");
                                    // setSpeed(2);
                                }}
                                type="button"
                                className="vf-btn vf-btn--sm vf-btn--primary"
                            >
                                Ei
                            </QuizButton>
                        </div>
                    </div>
                    <Answer
                        animate={question2 ? "open" : "closed"}
                        variants={variants}
                        transition={{
                            type: "ease",
                            default: { duration: 0.4 },
                        }}
                    >
                        <CheckAnswer>
                            {rightAnswer === false ? (
                                <>
                                    <img src={rightAnswerIcon} />{" "}
                                    <h4>Oikea vastaus</h4>
                                </>
                            ) : (
                                <>
                                    <img src={wrongAnswerIcon} />{" "}
                                    <h4>Väärä vastaus</h4>
                                </>
                            )}
                        </CheckAnswer>
                        <p>
                            Ei vielä. Tutkimme, miten kelluvia
                            merituulivoimaloita voidaan käyttää vedyn
                            laajamittaiseen tuotantoon.
                        </p>
                        <motion.p
                            animate={{
                                x: question2 ? 0 : -50,
                                opacity: question2 ? 1 : 0,
                            }}
                            transition={{
                                delay: 0.4,
                                type: "spring",
                                stiffness: 100,
                            }}
                            className="next"
                            onClick={() => setCurrentQuestion(3)}
                        >
                            &#62; Seuraava kysymys
                        </motion.p>
                    </Answer>
                </Question>
            )}

            {currentQuestion === 3 && (
                <Question>
                    <div className="question">
                        {/* <p style={{ marginBottom: "-3px", fontSize: "14px" }}>
                            3/3
                        </p> */}
                        <h4>Kysymys 3:</h4>
                        <p>Onko olemassa hyvää ja huonoa vetyä?</p>
                    </div>
                    <div className="options">
                        <div className="buttons">
                            <QuizButton
                                selected={selectedAnswer === "Kyllä"}
                                onClick={() => {
                                    setQuestion3(true);
                                    setRightAnswer(true);
                                    setSelectedAnswer("Kyllä");
                                    // setSpeed(2);
                                }}
                                type="button"
                                className="vf-btn vf-btn--sm vf-btn--primary"
                            >
                                Kyllä
                            </QuizButton>
                            <QuizButton
                                selected={selectedAnswer === "Ei"}
                                onClick={() => {
                                    setQuestion3(true);
                                    setRightAnswer(false);
                                    setSelectedAnswer("Ei");
                                    // setSpeed(3);
                                }}
                                type="button"
                                className="vf-btn vf-btn--sm vf-btn--primary"
                            >
                                Ei
                            </QuizButton>
                        </div>
                    </div>
                    <Answer
                        animate={question3 ? "open" : "closed"}
                        variants={variants}
                        transition={{
                            type: "ease",
                            default: { duration: 0.4 },
                        }}
                    >
                        <CheckAnswer>
                            {rightAnswer === true ? (
                                <>
                                    <img src={rightAnswerIcon} />{" "}
                                    <h4>Oikea vastaus</h4>
                                </>
                            ) : (
                                <>
                                    <img src={wrongAnswerIcon} />{" "}
                                    <h4>Väärä vastaus</h4>
                                </>
                            )}
                        </CheckAnswer>
                        <p>
                            Kyllä on. Vedyn tulee olla tuotettu fossiilivapaan
                            sähkön avulla, jotta sitä voidaan pitää
                            fossiilivapaana polttoaineena.
                        </p>
                    </Answer>
                </Question>
            )}
        </Container>
    );
};
