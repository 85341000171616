import React from "react";
import styled from "@emotion/styled";
import flyingHat from "../assets/images/se-flight4-22/hat.png";
import flyingHatMobile from "../assets/images/se-flight4-22/hat-mobile.png";
import { useMediaQuery } from "react-responsive";

const FlyingHatContainer = styled.div`
    .flier {
        /* Adjust animation duration to change the element’s speed */
        animation: flyinghat 40s linear;
        pointer-events: none !important;
        top: 0;
        left: 0;
        transform: translateX(-150%) translateY(-150%) rotateZ(0);
        position: fixed;
        animation-delay: 10s;
        animation-duration: 10s;
        z-index: 999999;
    }

    @keyframes flyinghat {
        98.001%,
        0% {
            display: block;
            transform: translateX(-200%) translateY(100vh) rotateZ(0deg);
        }

        15% {
            transform: translateX(100vw) translateY(-100%) rotateZ(300deg);
        }

        15.001%,
        18% {
            transform: translateX(100vw) translateY(-30%) rotateZ(0deg);
        }

        40% {
            transform: translateX(-200%) translateY(3vh) rotateZ(-300deg);
        }

        40.001%,
        43% {
            transform: translateX(-200%) translateY(-100%) rotateZ(-300deg);
        }

        65% {
            transform: translateX(100vw) translateY(50vh) rotateZ(0deg);
        }

        65.001%,
        68% {
            transform: translateX(20vw) translateY(-200%) rotateZ(300deg);
        }

        95% {
            transform: translateX(10vw) translateY(100vh) rotateZ(0deg);
        }
    } ;
`;

export const FlyingHat = () => {
    const isDesktop = useMediaQuery({
        query: "(min-width: 600px)",
    });

    return (
        <>
            <FlyingHatContainer>
                <div className="flier">
                    {isDesktop ? (
                        <img src={flyingHat} />
                    ) : (
                        <img src={flyingHatMobile} />
                    )}
                </div>
            </FlyingHatContainer>
        </>
    );
};
