import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import quote from "../assets/images/quote.svg";
// import { css } from "@emotion/react";

// Interfaces
interface CaseProps {
    text: string;
    quoteName: string;
    quoteText?: string;
    backgroundColor: string;
    textWidth: string;
    video: string;
    link?: string;
    startVideo: boolean;
}

// Styling
const Root = styled.div<{ backgroundColor: string; textWidth: string }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    background-color: ${(props) => props.backgroundColor};
    margin: 2rem -2rem;
    padding: 0;
    flex-flow: column;
    height: auto;
    padding-top: 0px;
    padding-bottom: 40px;
    ${(props) => props.theme.mq[2]} {
        width: calc(100% + 80px);
        padding: 0 15px;
        margin: 5rem 0;
        flex-flow: row;
        height: 350px;
    }
    @media screen and (max-width: 1124px) {
        height: 600px;
    }
    @media screen and (max-width: 992px) {
        height: auto;
        padding-top: 40px;
    }
    @media screen and (max-width: 480px) {
        padding-bottom: 0;
        padding-top: 0;
    }

    .content {
        width: 44%;
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: end;
        padding-left: 20px;
        @media screen and (max-width: 992px) {
            padding: 20px;
            width: 95%;
            margin-top: 40px;
        }
        p {
            text-align: right;
            z-index: 2;
            position: relative;
            color: #4e4b48;
        }
        p:first-of-type {
            font-weight: 500;
            line-height: 31px;
            font-size: 26px;
            max-width: ${(props) => props.textWidth};
        }
        p:nth-of-type(2) {
            font-style: italic;
            line-height: normal;
        }
        p:last-of-type {
            font-style: italic;
            margin-bottom: 0;
        }
        img {
            position: absolute;
            top: -15%;
            left: 18%;
            width: 250px;
            z-index: 1;
            @media screen and (max-width: 992px) {
                width: 220px;
                top: -5%;
                left: 40%;
            }
            @media screen and (max-width: 480px) {
                width: 220px;
                top: -5%;
                left: 5%;
            }
        }
    }
`;

const Container = styled.div`
    width: 100%;
    max-width: 1380px;
    display: flex;
    flex-flow: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 5rem;
    @media screen and (max-width: 992px) {
        flex-flow: column;
        padding: 0;
    }
    @media screen and (max-width: 480px) {
        padding-top: 20px;
    }
`;

const Section = styled.section`
    width: 100%;
    max-width: 622px;
    position: relative;
    overflow: hidden;
    border-left: none;
    border-right: none;
    padding-bottom: 29.25%;
    margin-top: 100px;
    @media screen and (max-width: 992px) {
        margin-top: 0px;
        max-width: 90%;
        padding-bottom: 47.25%;
    }
    @media screen and (max-width: 1124px) {
        margin-top: 0px;
    }
`;

const Video = styled.video`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: 2px solid #969696;
    background-color: #fff;
`;

// Markup
const Article = ({
    video,
    text,
    quoteName,
    quoteText,
    backgroundColor,
    textWidth,
    startVideo,
}: CaseProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const playVideo = () => {
        videoRef.current?.play();
    };

    useEffect(() => {
        if (startVideo) {
            playVideo();
        }
    }, [startVideo]);

    return (
        <Root backgroundColor={backgroundColor} textWidth={textWidth}>
            <Container>
                <Section>
                    <Video muted playsInline loop ref={videoRef}>
                        <source src={video} type="video/mp4" />
                    </Video>
                </Section>
                <div className="content">
                    <p>{text}</p>
                    <p style={{ marginBottom: 0 }}>{quoteName}</p>
                    <img src={quote} alt="quote" />
                    <p>{quoteText}</p>
                </div>
            </Container>
        </Root>
    );
};

export const QuoteVideo = Article;
