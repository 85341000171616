import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const Root = styled.div`
    width: 100%;
    background: #ebf2f3;
    max-width: none;
    margin: 0;
    nav p {
        margin-bottom: 5px;
    }
    nav a {
        font-size: 16px;
        margin-bottom: 12px;
        margin-top: 0;
        padding: 0;
        line-height: normal;
    }

    .vf-container {
        width: 100%;
        margin: auto;
        max-width: 1425px;
    }
    .item-list {
        width: 20%;
        text-align: left;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
            text-align: left;
            margin-bottom: 30px;
        }
    }
    .vf-row {
        @media screen and (max-width: 768px) {
            display: table;
            margin: auto;
        }
    }
    .vf-footer__social-links {
        @media screen and (max-width: 768px) {
            a {
                margin-top: 8px;
                padding-right: 3px;
            }
        }
    }
    .vf-footer {
        padding-bottom: 40px;
    }
`;

const CopyRight = styled.div`
    width: 100%;
    max-width: 1300px;
    margin: auto;
    display: flex;
    border-top: 1px solid white;
    padding-top: 16px;
    padding-bottom: 28px;
    justify-content: space-between;

    a {
        color: #000;
        margin-left: 10px;
        margin-right: 10px;
    }

    @media screen and (max-width: 768px) {
        display: block;
        text-align: center;
        > div {
            margin-bottom: 10px;
        }
    }
`;

// Markup
export const FooterFi = () => {
    return (
        <Root>
            <footer className="vf-footer">
                <div className="vf-container">
                    <div className="vf-row">
                        <div className="item-list">
                            <nav className="vf-footer__nav">
                                <p
                                    className="vf-footer__nav-item"
                                    css={css`
                                        font-weight: 600;
                                    `}
                                >
                                    Tuotteemme
                                </p>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.fi/sahkosopimukset/"
                                >
                                    Sähkösopimukset
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.fi/aurinkopaneeli/"
                                >
                                    Aurinkopaneelit
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.fi/ilmalampopumppu/"
                                >
                                    Ilmalämpöpumput
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.fi/palveluhinnasto/"
                                >
                                    Palveluhinnasto
                                </a>
                            </nav>
                        </div>
                        <div className="item-list">
                            <nav className="vf-footer__nav">
                                <p
                                    className="vf-footer__nav-item"
                                    css={css`
                                        font-weight: 600;
                                    `}
                                >
                                    Asiakaspalvelu
                                </p>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.fi/asiakaspalvelu/"
                                >
                                    Tarvitsetko apua?
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.fi/sahkosopimus-muutossa/"
                                >
                                    Oletko muuttamassa?
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.fi/asiakaspalvelu/usein-kysyttyja-kysymyksia/"
                                >
                                    Usein kysytyt kysymykset
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.fi/asiakaspalvelu/sopimusehdot/"
                                >
                                    Sopimusehdot
                                </a>
                            </nav>
                        </div>
                        <div className="item-list">
                            <nav className="vf-footer__nav">
                                <p
                                    className="vf-footer__nav-item"
                                    css={css`
                                        font-weight: 600;
                                    `}
                                >
                                    Oma energia
                                </p>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://omaenergia.vattenfall.fi/"
                                >
                                    Kirjaudu Oma energiaan
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.fi/asiakaspalvelu/aihe/oma-energia-palvelu-rd/lisatietoa-oma-energia-palvelusta/"
                                >
                                    Lue lisää Oma energiasta
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.fi/asiakaspalvelu/aihe/oma-energia-palvelu-rd/oma-energiankulutukseni/"
                                >
                                    Oma energiankulutukseni
                                </a>
                            </nav>
                        </div>
                        <div className="item-list">
                            <nav className="vf-footer__nav">
                                <p
                                    className="vf-footer__nav-item"
                                    css={css`
                                        font-weight: 600;
                                    `}
                                >
                                    Lue lisää
                                </p>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://corporate.vattenfall.fi/"
                                >
                                    Tietoa Vattenfallista
                                </a>
                                <a
                                    className="vf-footer__nav-item"
                                    href="https://www.vattenfall.fi/energianeuvonta/RD-energiaperhe/"
                                >
                                    Energiaperhe
                                </a>
                            </nav>
                        </div>
                        <div className="item-list">
                            <div
                                className="vf-footer__social-links"
                                css={css`
                                    text-align: left;
                                `}
                            >
                                <p
                                    css={css`
                                        font-size: 16px;
                                        font-weight: 600;
                                        margin-top: 3px;
                                        margin-bottom: 0;
                                        text-align: left;
                                    `}
                                >
                                    Sosiaalinen media
                                </p>

                                <a href="https://www.facebook.com/vattenfall.suomi">
                                    <span
                                        className="p-1 vf-tooltip vf-tooltip--blue-solid vf-icon-facebook"
                                        data-tooltip="vf-icon-facebook"
                                    ></span>
                                </a>
                                <a href="https://www.instagram.com/vattenfall/">
                                    <span
                                        className="p-1 vf-tooltip vf-tooltip--blue-solid vf-icon-instagram"
                                        data-tooltip="vf-icon-instagram"
                                    ></span>
                                </a>
                                <a href="https://www.youtube.com/user/VattenfallFinland">
                                    <span
                                        className="p-1 vf-tooltip vf-tooltip--blue-solid vf-icon-youtube"
                                        data-tooltip="vf-icon-youtube"
                                    ></span>
                                </a>
                                <a href="https://www.linkedin.com/company/vattenfall">
                                    <span
                                        className="p-1 vf-tooltip vf-tooltip--blue-solid vf-icon-linkedin"
                                        data-tooltip="vf-icon-linkedin"
                                    ></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <CopyRight>
                <div>© Vattenfall</div>
                <div>
                    <a href="https://www.vattenfall.fi/kayttoehdot-ja-tietosuojaseloste/kayttoehdot/">
                        Käyttöehdot
                    </a>
                    <a href="https://www.vattenfall.fi/kayttoehdot-ja-tietosuojaseloste/tietosuojaseloste/">
                        Tietosuojaseloste
                    </a>
                </div>
            </CopyRight>
        </Root>
    );
};
