import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";

// Interfaces
interface CaseProps {
    video: string;
    startVideo: boolean;
}

// Styling
const Root = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) => props.theme.mq[2]} {
        width: calc(100% + 80px);
        flex-flow: row;
        height: 350px;
    }
`;

const Container = styled.div`
    width: 100%;
    max-width: 1380px;
    display: flex;
    justify-content: center;
`;

const Section = styled.section`
    width: 100%;
    position: relative;
    border-left: none;
    border-right: none;
    padding-bottom: 29.25%;
    margin-top: 100px;
    @media screen and (max-width: 992px) {
        margin-top: 0px;
        max-width: 90%;
        padding-bottom: 47.25%;
    }
    @media screen and (max-width: 1124px) {
        margin-top: 0px;
    }
`;

const Video = styled.video`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #fff;
`;

// Markup
const Article = ({ video, startVideo }: CaseProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const playVideo = () => {
        videoRef.current?.play();
    };

    useEffect(() => {
        if (startVideo) {
            playVideo();
        }
    }, [startVideo]);

    return (
        <Root>
            <Container>
                <Section>
                    <Video muted playsInline loop ref={videoRef}>
                        <source src={video} type="video/mp4" />
                    </Video>
                </Section>
            </Container>
        </Root>
    );
};

export const InfoVideo = Article;
