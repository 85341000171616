// Images
import steel from "../assets/images/steel-circle.png";
import datacenter from "../assets/images/circle-datacenter.png";
import ocean from "../assets/images/ocean-circle.png";
import aviation from "../assets/images/aviation-circle.png";
import solar from "../assets/images/solar-circle.png";
// import hydrogen from "../assets/images/fi-flight4-22/hydrogen-circle.png";

type location = {
    x: number;
    y: number;
    xMobile: number;
    yMobile: number;
    text: string;
    image: string;
};

export const locationsFinnish: location[] = [
    {
        x: 1035,
        xMobile: 1084,
        y: 353,
        yMobile: 363,
        text: "Fossiilivapaa teräs",
        image: steel,
    },
    // {
    //     x: 810,
    //     xMobile: 940,
    //     y: 500,
    //     yMobile: 450,
    //     text: "Lisää tuulivoimaa",
    //     image: ocean,
    // },
    {
        x: 870,
        xMobile: 980,
        y: 637,
        yMobile: 550,
        text: "Fossiilivapaa lento-polttoaine ",
        image: aviation,
    },
    {
        x: 1165,
        xMobile: 1180,
        y: 380,
        yMobile: 370,
        text: "Lisää aurinkovoimaa",
        image: solar,
    },
    {
        x: 1100,
        xMobile: 1130,
        y: 290,
        yMobile: 320,
        text: "Fossiilivapaat datakeskukset",
        image: datacenter,
    },
    {
        x: 770,
        xMobile: 910,
        y: 530,
        yMobile: 490,
        text: "Lisää tuulivoimaa",
        image: ocean,
    },
    {
        x: 1105,
        xMobile: 1135,
        y: 390,
        yMobile: 380,
        text: "Lisää tuulivoimaa",
        image: ocean,
    },
];
